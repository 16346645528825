import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['value']

  stepUp () {
    const result = this.value + this.stepVal
    this.valueTarget.value = result
  }

  stepDown () {
    const result = this.value - this.stepVal
    this.valueTarget.value = result
  }

  get value () {
    // Only allow integers and decimals
    if (/^[-+]?(\d+\.?\d*)$/.test(this.valueTarget.value)) {
      return Number(this.valueTarget.value)
    }

    return 0;
  }

  get stepVal() {
    return !!this.valueTarget.step ? Number(this.valueTarget.step) : 1
  }
}
