import { Controller } from '@hotwired/stimulus'
// import * as bootstrap from 'bootstrap'
import Tooltip from 'bootstrap/js/dist/tooltip'

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = { msg: String, placement: { type: String, default: 'top' }, offset: { type: Array, default: [0, 0] } }

  connect () {
    this.tooltip = new Tooltip(this.element, {
      title: this.msgValue,
      placement: this.placementValue,
      offset: this.offsetValue
    })
  }

  disconnect () {
    this.tooltip.dispose()
  }
}
