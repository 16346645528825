import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  async checkUnsaved(evt) {
    if (evt.target.getAttribute('data-ignore-unsaved') == 'true') {
      return
    }

    const unsaved = this.element.querySelector('[data-unsaved]') != null

    if (unsaved) {
      evt.preventDefault()

      const proceed = await this.prompt()

      if (!proceed) {
        return
      }

      evt.target.setAttribute('data-ignore-unsaved', 'true')
      evt.target.click()
    }
  }

  prompt() {
    const dialog = document.getElementById('turbo-confirm')
    dialog.querySelector('p').textContent = 'Discard changes?'
    dialog.showModal()
  
    return new Promise((resolve, reject) => {
      dialog.addEventListener('close', () => {
        resolve(dialog.returnValue === 'confirm')
      }, { once: true })
    })
  }
}
