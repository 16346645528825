import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    expanded: Boolean
  }

  toggleAll() {
    this.expandedValue == false ? this.expandAll() : this.collapseAll()
    this.expandedValue = !this.expandedValue
  }

  collapseAll() {
    const expandBtn = this.element.querySelectorAll(`.expand-btn`)
    const itemsDetails = this.element.querySelectorAll(`.group-detail-item`)
    expandBtn.forEach(btn => {
      btn.classList = 'bi bi-chevron-down expand-btn'
    })

    itemsDetails.forEach(itemDetail => {
      itemDetail.style.display = 'none';
    })
  }

  expandAll() {
    const expandBtn = this.element.querySelectorAll(`.expand-btn`)
    const itemsDetails = this.element.querySelectorAll(`.group-detail-item`)
    expandBtn.forEach(btn => {
      btn.classList = 'bi bi-chevron-up expand-btn'
    })

    itemsDetails.forEach(itemDetail => {
      itemDetail.style.display = 'table-row';
    })
  }
}
