import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "ifTrue", "ifFalse", "toggleInput" ]

  connect () {
    this.updateDisplay()
  }

  toggle() {
    this.toggleInputTarget.value = this.toggleInputTarget.value == "true" ? "false" : "true"
    this.updateDisplay()
  }

  updateDisplay() {
    if (this.toggleInputTarget.value == "true") {
      this.ifTrueTarget.classList.remove("d-none")
      this.ifFalseTarget.classList.add("d-none")
    } else {
      this.ifTrueTarget.classList.add("d-none")
      this.ifFalseTarget.classList.remove("d-none")
    }
  }
}
