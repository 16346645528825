import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="search"
export default class extends Controller {
  static targets = [ "outerForm" ]

  submitOuterForm(event) {
    const path = event.target.dataset.path || event.target.parentElement.dataset.path;
    this.outerFormTarget.action = path;
    this.outerFormTarget.requestSubmit();
  }
}
