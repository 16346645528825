import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["statusField"];

  saveWithAction(evt) {
    const selectedEl = this.findParentItem(evt.target)
    const status = selectedEl.dataset.value;
    if (status != 'default') {
      this.statusFieldTarget.value = status;
    }

    this.element.requestSubmit()
  }

  findParentItem(element) {
    while (element && element.tagName !== "LI" && element && element.tagName !== "BUTTON") {
      element = element.parentElement
    }

    return element
  }
}
