import { Controller } from '@hotwired/stimulus'
import debounce from 'debounce'

// Connects to data-controller="search"
export default class extends Controller {
  static values = {
    debounce: { type: Number, default: 500 }
  }

  initialize () {
    this.submit = debounce(this.submit.bind(this), this.debounceValue)
  }

  submit (_event) {
    this.element.requestSubmit()
  }
}
