import { Controller } from '@hotwired/stimulus'
import { Collapse as bsCollapse } from 'bootstrap';

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", (evt) => {
      this.accordionFormChanged(evt)
    });

    document.addEventListener("isLoaded", (evt) => {
      const el = evt.detail.callerTarget

      if (el.hasAttribute('data-todo-active')) {
        const removeItem = el.hasAttribute('data-todo-remove')
        this.toggleAccordionItem(el.id, removeItem)
      }

      // handle change detection for removing an entry item
      if (el.hasAttribute('data-estimate-entry-removed') && el.hasAttribute('data-estimate-entry-container-id')) {
        const entryContainer = this.element.querySelector(`#${el.getAttribute('data-estimate-entry-container-id')}`)
        if (entryContainer != null) {
          this.accordionFormChanged({ target: entryContainer })
        }
      }
    });
  }

  async todoAccordionClicked(evt) {
    const id = this.findParentByDataAttrib(evt.target, "data-todo-accordion-item").id
    const unsavedChanges = this.element.querySelector('[data-unsaved]')

    if (unsavedChanges != null) {
      const proceed = await this.prompt()

      if (!proceed) {
        return
      }      
    }

    const activeAccordionItemEl = this.findParentByDataAttrib(this.element.querySelector('[data-todo-active]'), 'data-todo-accordion-item')

    if (activeAccordionItemEl != null && activeAccordionItemEl.id != id) {
      this.toggleAccordionItem(activeAccordionItemEl.id)
    }

    this.toggleAccordionItem(id)
  }

  toggleAccordionItem(id, removeItem = false) {
    const accordionItemEl = this.element.querySelector(`#${id}`)
    const collapseEl = accordionItemEl.querySelector('.accordion-collapse')
    const accordionBtnEl = accordionItemEl.querySelector('.accordion-button')
    const cancelBtnEl = accordionItemEl.querySelector('.cancel-btn')
    const control = new bsCollapse(collapseEl, { toggle: false })

    accordionBtnEl.classList.toggle('collapsed')
    control.toggle()

    setTimeout(() => {
      if (removeItem) {
        accordionItemEl.addEventListener('animationend', () => {
          control.dispose()
          const turboFrameEl = this.element.querySelector(`#${id}_frame`)
          turboFrameEl.remove();
        });

        accordionItemEl.style.animation = 'slide-up .5s'
      } else {
        control.dispose()
      }
    }, 600)

    if (accordionItemEl.hasAttribute('data-todo-active')) {
      accordionItemEl.removeAttribute('data-todo-active')
    } else {
      accordionItemEl.setAttribute('data-todo-active', true)
    }

    if (accordionItemEl.hasAttribute('data-unsaved')) {
      cancelBtnEl.click()
    }
  }

  findParentByDataAttrib(element, dataName) {
    while (element && !element.hasAttribute(dataName)) {
      element = element.parentElement
    }

    return element
  }

  accordionFormChanged(evt) {
    console.log('data changed')
    const accordionItemEl = this.findParentByDataAttrib(evt.target, 'data-todo-accordion-item')
    accordionItemEl.setAttribute('data-unsaved', true)
  }

  prompt() {
    const dialog = document.getElementById('turbo-confirm')
    dialog.querySelector('p').textContent = 'Discard changes?'
    dialog.showModal()
  
    return new Promise((resolve, reject) => {
      dialog.addEventListener('close', () => {
        resolve(dialog.returnValue === 'confirm')
      }, { once: true })
    })
  }
}
