import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const customEvent = new CustomEvent("isLoaded", { detail: { callerTarget: this.element } });
    document.dispatchEvent(customEvent);
  }

  disconnect() {
    const customEvent = new CustomEvent("unLoaded", { detail: { callerTarget: this.element } });
    document.dispatchEvent(customEvent);
  }
}
