import { Controller } from '@hotwired/stimulus'
import { marked } from 'marked'

// Connects to data-controller="markdown-display"

export default class extends Controller {
  connect () {
    this.element.classList.add('d-none')
    const div = document.createElement('div')
    div.innerHTML = marked.parse(this.element.textContent)

    this.element.replaceWith(div)
  }
}
