import { Controller } from '@hotwired/stimulus'
import { marked } from 'marked'
import hljs from 'highlight.js'

marked.setOptions({
  renderer: new marked.Renderer(),
  highlight: function (code, lang) {
    const language = hljs.getLanguage(lang) ? lang : 'plaintext'
    return hljs.highlight(code, { language }).value
  },
  langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  smartypants: false,
  xhtml: false
})

// Connects to data-controller="markdown"
export default class extends Controller {
  static targets = ['input', 'output']

  connect () {
    this.parse()
  }

  change () {
    this.parse()
  }

  parse () {
    this.outputTarget.innerHTML = marked.parse(this.inputTarget.value)
  }
}
