// Entry point for the build script in your package.json
import { Turbo } from '@hotwired/turbo-rails'
import './controllers'
import LocalTime from 'local-time'

Turbo.setProgressBarDelay(100)
LocalTime.start()

Turbo.setConfirmMethod((message, element) => {
  const dialog = document.getElementById('turbo-confirm')
  dialog.querySelector('p').textContent = message
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm')
    }, { once: true })
  })
})
